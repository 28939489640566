import useJwt from "@/auth/jwt/useJwt";
const axios = useJwt.axiosIns;

export async function getJobs() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/job`);
  return res.data.jobs;
}

export async function getJob(id) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/job/${id}`);
  return res.data.job;
}

export async function addJob(file) {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/job`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
}
